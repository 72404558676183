import { Box } from '@material-ui/core'
import {
  BannerBox,
} from './styles'
import { ShowBanner } from './ShowBanner'



function Banner({ handlePay, ...props }) {
  return (
    <>
      <BannerBox {...props}>
        <Box display={'flex'} flexDirection="column" alignItems="center" >
          <ShowBanner handlePay={handlePay} />
        </Box>
      </BannerBox>
    </>
  )
}

export default Banner
