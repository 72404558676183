import { Logo, Bar, HelpButton } from './styles'
import config from 'config'

function HeaderBar(props) {
  return (
    <Bar {...props}>
      <a href={config.infinitePayLink}>
        <Logo />
      </a>
    </Bar>
  )
}

export default HeaderBar
