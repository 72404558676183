
import { Box } from '@material-ui/core';
import { BannerText, BannerText5 } from './styles';
import { usePaymentContext } from 'context/PaymentContext';

export function InvalidAmount() {
  const  {userData} = usePaymentContext();
  return <Box>
    <BannerText5>Peça um novo link de pagamento para</BannerText5>
    <BannerText>
      <strong>${userData.id}</strong>
    </BannerText>
  </Box>;
}
