import HeaderBar from './HeaderBar'
import Display from './Display'
import { HeaderBox } from './styles'

import { Collapse } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'

function NewHeader({ ...props }) {
  const payments = useSelector(selectPayments)
  return (
    <HeaderBox component="header" shrink={!payments.active}  {...props}>
      <Collapse in={!payments.active}>
        <HeaderBar />
      </Collapse>
      <Display shrink={payments.active} />
    </HeaderBox>
  )
}

export default NewHeader
