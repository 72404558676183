
import { Box, Hidden, Button } from '@material-ui/core';
import {
  BannerText3,
  BannerText4
} from './styles';
import cancel from 'assets/cancel.svg';
import Image from 'next/image';
import ELEMENT_TEST_ID from '/playwright/support/elements';
import { isMobile } from 'react-device-detect'


export function InvalidUserData() {
  return <>
    <Hidden smDown>
      <Box display="flex" flexDirection="row" marginBottom={1} alignItems="center">
        <Image height={25} width={25} alt="cancel" src={cancel} />
        <Box marginLeft={'10px'} />
        <BannerText3 data-testid={ELEMENT_TEST_ID.notFoundTitleText}>
          <strong>Link não encontrado</strong>
        </BannerText3>
      </Box>
    </Hidden>
    <Hidden mdUp>
      <Box
        display="flex"
        flexDirection="column"
        marginBottom={1}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          height={isMobile ? 30 : 25}
          width={isMobile ? 30 : 25}
          alt="Link não encontrado"
          src={cancel} />
        <Box marginTop={1} />
        <BannerText3 data-testid={ELEMENT_TEST_ID.notFoundTitleText}>
          <strong>Link não encontrado</strong>
        </BannerText3>
      </Box>
    </Hidden>
    <BannerText4 data-testid={ELEMENT_TEST_ID.notFoundDescriptionText}>
      Por favor, entre em contato com o vendedor para obter um novo link.
    </BannerText4>
  </>;
}
