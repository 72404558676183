import { Box, Hidden } from '@material-ui/core'
import Footer from 'components/Footer'
import { DisplayBox, FlowMarketingBanner, FlowMarketingBannerMobile } from './styles'
import Banner from './Banner'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showForm } from 'reducers/payments'

function Display({ shrink, ...props }) {  
  const dispatch = useDispatch()
  const handlePay = useCallback(async () => {
    await dispatch(showForm())
  }, [dispatch])

  return (
    <DisplayBox shrink={shrink} {...props} style={{minWidth: '320px'}} >
      <Box flex={1} display={'flex'} style={{gap: '16px', 'transition': 'all 0.368s ease-in-out'}} flexDirection={'column'} justifyContent={'center'}>
        <Banner handlePay={handlePay} />

        <Hidden smDown>
          <FlowMarketingBanner />
        </Hidden>
        <Hidden mdUp>
          <FlowMarketingBannerMobile /> 
        </Hidden>
      </Box>
      <Footer color="light" />
    </DisplayBox>
  )
}

export default Display
