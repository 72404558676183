import { Box } from '@material-ui/core'
import {
  BannerText1,
  PaymentInfoTitle,
  PaymentInfoHandle,
  PaymentInfoDocumentNumber,
  PaymentInfoFantasyName,
  PaymentInfoSubTitle,
  PaymentInfoInstallments,
} from './styles'
import canPay from 'utils/canPay'
import { maskCNPJ } from 'utils/form-masks'
import ELEMENT_TEST_ID from '/playwright/support/elements'
import { PayContainer } from './PayContainer'
import { usePaymentContext } from 'context/PaymentContext'
import { formatCurrency } from 'utils/format-currency'
import { useWalletPayStore } from 'libs/useWalletPay'

export function ValidAmount({handlePay}) {
  const installment = useWalletPayStore((state) => state.installment);
  const { userData, amount, invoiceData, valueWithFees } = usePaymentContext()
  const showPayButton = canPay(amount)
  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '0.6rem' }}>
        <PaymentInfoTitle component="h2" data-testid={ELEMENT_TEST_ID.titleText}>
          Você está pagando para
        </PaymentInfoTitle>
        <Box display="flex" flexDirection="column" textAlign="center">
          <PaymentInfoHandle component="h2" data-testid={ELEMENT_TEST_ID.handleText}>
            ${userData.id}
          </PaymentInfoHandle>
          <Box display="flex" flexDirection="column">
            <PaymentInfoFantasyName component="h2" data-testid={ELEMENT_TEST_ID.fantasyNameText}>
              {userData.fantasy_name}
            </PaymentInfoFantasyName>
            <PaymentInfoDocumentNumber
              component="h2"
              data-testid={ELEMENT_TEST_ID.documentNumberText}
            >
              {userData.document_number > 11
                ? `${maskCNPJ(userData.document_number)}`
                : 'Pessoa Física'}
            </PaymentInfoDocumentNumber>
          </Box>
        </Box>
        {invoiceData?.info?.reference && (
          <PaymentInfoSubTitle component="h3">{`${invoiceData.info.reference}`}</PaymentInfoSubTitle>
        )}
      </Box>
      <Box margin={'8px auto'}>
        <BannerText1 data-testid={ELEMENT_TEST_ID.amountText}>
          {formatCurrency(amount)}
        </BannerText1>
        { invoiceData?.info?.transfer_fees && installment > 0 &&  <PaymentInfoInstallments> {`${installment}x de ${formatCurrency(valueWithFees[installment-1].installment_value)}`} </PaymentInfoInstallments>}
      </Box>
      <BannerText1 data-testid={ELEMENT_TEST_ID.amountText}>
        {userData.amount?.formattedValue}
      </BannerText1>
      {showPayButton &&  <PayContainer handlePay={handlePay} />}
    </>
  )
}
