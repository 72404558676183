import { ValidAmount } from './ValidAmount';
import { InvalidAmount } from './InvalidAmount';
import { InvalidUserData } from './InvalidUserData';
import { usePaymentContext } from 'context/PaymentContext';


export function ShowBanner({handlePay}) {
  const  {userData, amount} = usePaymentContext()
  if (Object.keys(userData).length > 0) {
    if (!amount) {
      return <InvalidAmount />;
    }
    else {
      return <ValidAmount handlePay={handlePay} />;
    }
  } else {
    return <InvalidUserData />;
  }
}
