import { useCallback } from "react";
import useRudderStack from "utils/useRudderStack";


function BannerLink(props) {
    const {rudderStack} = useRudderStack();
    const onClick = () => {
        rudderStack.track("Payment Link Banner")
    }
    return (
        <a {...props} onClick={onClick} target="_blank" />
    )
}

export default BannerLink