export const hasValidUserData = (amount) => {
  return amount > 0;
};



export const canPay = (amount) => {
  const showPayButton = hasValidUserData(amount);
  return showPayButton;
};

export default canPay;
